/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeleteFileDto } from '../models/delete-file-dto';

@Injectable({
  providedIn: 'root',
})
export class DeleteFileApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation softDeleteFile
   */
  static readonly SoftDeleteFilePath = '/files-sharing/admin/files/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softDeleteFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  softDeleteFile$Response(params: {
    files: string[];
    contentType: string;
    context?: HttpContext
    body: DeleteFileDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeleteFileApiService.SoftDeleteFilePath, 'post');
    if (params) {
      rb.path('files', params.files, {});
      rb.query('contentType', params.contentType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `softDeleteFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  softDeleteFile(params: {
    files: string[];
    contentType: string;
    context?: HttpContext
    body: DeleteFileDto
  }
): Observable<void> {

    return this.softDeleteFile$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation restoreFile
   */
  static readonly RestoreFilePath = '/files-sharing/admin/files/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restoreFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restoreFile$Response(params: {
    files: string[];
    contentType: string;
    context?: HttpContext
    body: DeleteFileDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeleteFileApiService.RestoreFilePath, 'post');
    if (params) {
      rb.path('files', params.files, {});
      rb.query('contentType', params.contentType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `restoreFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restoreFile(params: {
    files: string[];
    contentType: string;
    context?: HttpContext
    body: DeleteFileDto
  }
): Observable<void> {

    return this.restoreFile$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation pollingDeleteFile
   */
  static readonly PollingDeleteFilePath = '/files-sharing/admin/batch-files-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pollingDeleteFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pollingDeleteFile$Response(params: {
    files: string[];
    contentType: string;
    context?: HttpContext
    body: DeleteFileDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeleteFileApiService.PollingDeleteFilePath, 'post');
    if (params) {
      rb.path('files', params.files, {});
      rb.query('contentType', params.contentType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pollingDeleteFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pollingDeleteFile(params: {
    files: string[];
    contentType: string;
    context?: HttpContext
    body: DeleteFileDto
  }
): Observable<void> {

    return this.pollingDeleteFile$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
