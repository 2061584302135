/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AdminRfscanFilesApiService } from './services/admin-rfscan-files-api.service';
import { MakePrivateApiService } from './services/make-private-api.service';
import { DeleteFileApiService } from './services/delete-file-api.service';
import { ResetReportApiService } from './services/reset-report-api.service';
import { EditMetaDataApiService } from './services/edit-meta-data-api.service';
import { MaintenanceModeApiService } from './services/maintenance-mode-api.service';
import { AppConfigurationsApiService } from './services/app-configurations-api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdminRfscanFilesApiService,
    MakePrivateApiService,
    DeleteFileApiService,
    ResetReportApiService,
    EditMetaDataApiService,
    MaintenanceModeApiService,
    AppConfigurationsApiService,
    ApiConfiguration
  ],
})
export class RfscanApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<RfscanApiModule> {
    return {
      ngModule: RfscanApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: RfscanApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('RfscanApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
